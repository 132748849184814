<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('tradeTariffReport.country_profile') }} {{$t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
          <b-row>
            <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="Country" vid="country_id" rules="" v-slot="{ errors }">
                <b-form-group
                  label-for="country_id"
                >
                <template v-slot:label>
                  {{ $t('globalTrans.country_name')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.country_id"
                  :options="tradeTariffCountryList"
                  id="country_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="" v-slot="{ errors }">
                <b-form-group
                  label-for="fiscal_year_id"
                >
                <template v-slot:label>
                  {{ $t('globalTrans.fiscal_year')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.fiscal_year_id"
                  :options="fiscalYearList"
                  id="fiscal_year_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          <b-col sm="9">
          </b-col>
          <b-col sm="3" style="text-align: right;">
            <b-button size="sm" type="submit" variant="primary" class="mt-20">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
        </b-form>
      </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>
    <b-overlay :show="unitLoading">
      <body-card>
        <!-- table section start -->
        <template v-slot:headerTitle>
          <h4 class="card-title text-left">{{ $t('tradeTariffReport.country_profile') }}</h4>
        </template>
        <template v-slot:body>
           <b-row class="text-right mt-2">
            <b-col>
              <b-button @click="pdfExport" class="btn btn-success btn-md">
                <i class="ri-file-pdf-line"></i>  {{  $t('globalTrans.pdf') }}
              </b-button>
            </b-col>
          </b-row>
            <b-row>
              <ReportHeading />
              <b-col md="12" v-if="hid === 1">
                  <table style="line-height: 25px" class="table profileTable table-sm table-bordered">
                      <tr>
                          <td colspan="2"><img :src="tradeTariffServiceBaseUrl +'storage/' + country_data.attachment_main" style="max-width: 200px;max-height: 200px;min-width: 200px;min-height: 200px;" alt="Not Found"></td>
                      </tr>
                      <tr>
                          <th>{{ $t('globalTrans.country_name')}} </th>
                          <td style="width:70%"> {{ getCountryName(country_data.country_id) }} </td>
                      </tr>
                      <tr>
                          <th>{{ $t('globalTrans.gdp')}} </th>
                          <td style="width:70%"> {{ $n(country_data.gdp, { useGrouping: false }) }} </td>
                      </tr>
                      <tr>
                          <th>{{ $t('globalTrans.population')}} </th>
                          <td style="width:70%"> {{ $n(country_data.population, { useGrouping: false }) }} </td>
                      </tr>
                      <tr>
                          <th>{{ $t('globalTrans.currency')}} </th>
                          <td style="width:70%"> {{ getCurrency(country_data.currency) }} </td>
                      </tr>
                      <tr>
                          <th>{{ $t('tradeTariffApp.pci')}} </th>
                          <td style="width:70%"> {{ $n(country_data.pci, { useGrouping: false }) }} </td>
                      </tr>
                      <tr>
                          <th>{{ $t('tradeTariffApp.sea_port')}}</th><td style="width:70%"> <span v-for="(data,index) in country_data.sea_ports" :key="index"> {{ getSeaPortName(data) }}, </span></td>
                      </tr>
                      <tr>
                          <th>{{ $t('tradeTariffApp.air_port')}}</th><td style="width:70%"> <span v-for="(data,index) in country_data.air_ports" :key="index"> {{ getAirPortName(data) }}, </span></td>
                      </tr>
                      <tr>
                          <th>{{ $t('tradeTariffApp.land_port') }}</th><td style="width:70%"> <span v-for="(data,index) in country_data.land_ports" :key="index"> {{ getLandPortName(data) }}, </span></td>
                      </tr>
                      <tr>
                          <th>{{ $t('tradeTariffApp.agricultural_product')}}</th><td style="width:70%"> <span v-for="(data,index) in country_data.agricultural_products" :key="index"> {{ getProductName(data) }}, </span></td>
                      </tr>
                      <tr>
                          <th>{{ $t('tradeTariffApp.industrial_product') }}</th><td style="width:70%"> <span v-for="(data,index) in country_data.industrial_products" :key="index"> {{ getProductName(data) }}, </span></td>
                      </tr>
                      <tr>
                          <th>{{ $t('tradeTariffReport.top_exporters') }}</th>
                          <td style="width:70%"> <span v-for="(data,index) in top_exporters" :key="index"> {{ data.consignee }}, </span></td>
                      </tr>
                      <tr>
                          <th>{{ $t('tradeTariffReport.top_importers')}} </th>
                          <td style="width:70%"> <span v-for="(data,index) in top_importers" :key="index"> {{ data.consignee }}, </span></td>
                      </tr>
                      <tr>
                        <td class="text-center" style="background-color: #32557a;color:white;" colspan="2"><b>{{$t('tradeTariffReport.trade_profile')}}</b></td>
                      </tr>
                      <tr>
                          <th>{{ $t('tradeTariffApp.avg_mfn_tariff_rate')}} </th>
                          <td style="width:70%"> {{ $n(mfn, { useGrouping: false }) }} </td>
                      </tr>
                      <tr>
                          <th>{{ $t('tradeTariffApp.wto_agri')}} </th>
                          <td style="width:70%"> {{ $n(wto_agri, { useGrouping: false }) }} </td>
                      </tr>
                      <tr>
                          <th>{{ $t('tradeTariffApp.wto_indus')}} </th>
                          <td style="width:70%"> {{ $n(wto_indus, { useGrouping: false }) }} </td>
                      </tr>
                      <tr>
                          <th>{{ $t('tradeTariffReport.rtas')}}</th>
                          <td style="width:70%">
                            <span v-for="(data,index) in current_signatories" :key="index">
                            {{$i18n.locale === 'en' ? data.rta_name_en : data.rta_name_bn}}
                            (
                              <span v-for="(data2,index) in data.current_signatories" :key="index">
                                {{ getCurrentSignatoriesName(data2) }}
                                <span v-if="index !== 1">-</span>
                              </span>
                            ),
                            </span>
                          </td>
                      </tr>
                  </table>
                  <table style="line-height: 25px" class="table profileTable table-sm table-bordered">
                      <tr class="text-center">
                      <td class="text-center" style="background-color: #32557a;color:white;" colspan="3"><b>{{$t('tradeTariffReport.bilateral_trade_current')}}</b></td>
                      </tr>
                    <tr>
                        <th>{{ $t('globalTrans.country_name')}} </th>
                        <th>{{ $t('tradeTariffApp.export_amount')}} </th>
                        <th>{{ $t('tradeTariffApp.import_amount')}} </th>
                    </tr>
                    <tr  v-for="(data3,index) in bilateral_trade" :key="index">
                        <td>{{ getCountryName(data3.associated_country) }} </td>
                        <td>{{ $n(data3.total_export, { useGrouping: false }) }} </td>
                        <td>{{ $n(data3.total_import, { useGrouping: false }) }} </td>
                    </tr>
                  </table>
              </b-col>
            </b-row>
        </template>
        <!-- table section end -->
      </body-card>
     </b-overlay>
  </div>
</template>
<style scoped>
  .profileTable tr th {
    background-color: lightslategray;
    color: white;
  }
</style>
<script>
import ReportHeading from '../../pages/report-heading/ReportHeading.vue'
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { countryInformationManagementReportList, tariffPortsLstApi } from '../../api/routes'

export default {
  components: {
    ReportHeading
  },
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      valid: null,
      country_data: '',
      ports: [],
      seaPortsList: [],
      landPortsList: [],
      airPortsList: [],
      top_exporters: [],
      top_importers: [],
      current_signatories: [],
      mfn: '',
      wto_agri: '',
      wto_indus: '',
      bilateral_trade: [],
      hid: 0,
      unitLoading: false,
      search: {
        country_id: 0,
        fiscal_year_id: 0,
        start_date: '',
        end_date: '',
        limit: 10
      }
    }
  },
  created () {
    this.getPortsList()
  },
  watch: {
    'search.country_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.hid = 0
      } else {
      }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    tradeTariffCountryList: function () {
      return this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.filter(item => item.status === 1)
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    }
  },
  methods: {
    getCurrentSignatoriesName (id) {
        const cateObj = this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.find(item => item.value === parseInt(id))
        if (cateObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
            } else {
                return cateObj.text_en
            }
        } else {
        return ''
        }
    },
    getCountryName (id) {
            const obj = this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.find(item => item.value === parseInt(id))
            if (obj) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            } else {
                return ''
            }
        },
        getCapitalName (id) {
            const obj = this.$store.state.TradeTariffService.commonObj.capitalInfoList.find(item => item.value === parseInt(id))
            if (obj) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            } else {
                return ''
            }
        },
        getCurrency (id) {
          const obj = this.$store.state.TradeTariffService.commonObj.currencyList.find(item => item.value === parseInt(id))
          if (obj) {
            return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
          } else {
            return ''
          }
        },
        getSeaPortName (id) {
            var obj = this.ports
            if (obj) {
                obj = obj.find(item => item.id === parseInt(id))
                if (obj) {
                    return this.$i18n.locale === 'en' ? obj.port_name_en : obj.port_name_bn
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        getAirPortName (id) {
            var obj = this.ports
            if (obj) {
                obj = obj.find(item => item.id === parseInt(id))
                if (obj) {
                    return this.$i18n.locale === 'en' ? obj.port_name_en : obj.port_name_bn
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        getLandPortName (id) {
            var obj = this.ports
            if (obj) {
                obj = obj.find(item => item.id === parseInt(id))
                if (obj) {
                    return this.$i18n.locale === 'en' ? obj.port_name_en : obj.port_name_bn
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        getProductName (id) {
            const obj = this.$store.state.TradeTariffService.commonObj.productList.find(item => item.status === 1 && item.value === parseInt(id))
            if (obj) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            } else {
                return ''
            }
        },
        getPortsList () {
            RestApi.getData(tradeTariffServiceBaseUrl, tariffPortsLstApi).then(response => {
                this.ports = response.data.filter(obj => obj.status === 1)
            })
        },
    searchData () {
      this.hid = 0
      this.unitLoading = true
      this.loadData()
    },
    async loadData () {
      if (this.search.fiscal_year_id) {
        this.search.start_date = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.search.fiscal_year_id).start_date
        this.search.end_date = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.search.fiscal_year_id).end_date
      }
      const params = Object.assign({}, this.search)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(tradeTariffServiceBaseUrl, countryInformationManagementReportList, params)
      if (result.success) {
        this.unitLoading = false
        this.hid = 1
        this.country_data = result.country_data
        this.top_exporters = result.top_exporters
        this.top_importers = result.top_importers
        this.current_signatories = result.current_signatories
        this.mfn = result.mfn
        this.wto_agri = result.wto_agri
        this.wto_indus = result.wto_indus
        this.bilateral_trade = result.bilateral_trade
      } else {
        this.unitLoading = false
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
     async pdfExport () {
        this.unitLoading = true
        const params = Object.assign({}, this.search, { request_type: 'pdf', local: this.$i18n.locale, org_id: 7 })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(tradeTariffServiceBaseUrl, countryInformationManagementReportList, params)
        var blob = new Blob([result], {
            type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        this.unitLoading = false
    }
  }
}
</script>
